const data = [
  {
    responsive_id: '',
    id: 95,
    full_name: 'Edwina Ebsworth',
    location: 'UBA Avenue Des Banques',
    transaction_id: 'MP32937',
    total_cost: '10,000 XAF',
    status: 'Pending',
    validation: false,
    date_time: 'Wednesday, 5 Jan. 2022 - 11:13 PM',
  },
  {
    responsive_id: '',
    id: 1,
    full_name: "Korrie O'Crevy",
    location: 'EMIA Yaounde',
    transaction_id: 'MP32037',
    total_cost: '10,000,000 XAF',
    status: 'Cancelled',
    validation: false,
    date_time: 'Wednesday, 5 Jan. 2021 - 11:03 AM',
  },
  {
    responsive_id: '',
    id: 7,
    full_name: "Falloon O'De",
    location: 'Total Melen Yaounde',
    transaction_id: 'MP12937',
    total_cost: '500,000 XAF',
    status: 'Validated',
    validation: true,
    date_time: 'Wednesday, 5 Jan. 2021 - 10:13 AM',
  },
  {
    responsive_id: '',
    id: 3,
    full_name: 'Stella Ganderton',
    location: 'Nso Boyz Yaounde',
    transaction_id: 'MP02937',
    total_cost: '35,000 XAF',
    status: 'Validated',
    validation: true,
    date_time: 'Wednesday, 5 Jan. 2021 - 11:00 AM',
  },
  {
    responsive_id: '',
    id: 4,
    full_name: 'Dorolice Crossman',
    transaction_id: 'MP22937',
    location: 'Polytechnique Yaounde',
    total_cost: '17,000 XAF',
    status: 'Validated',
    validation: true,
    date_time: 'Friday, 5 Jan. 2021 - 09:13 PM',
  },
]

export default data
