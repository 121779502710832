<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title class="">
          <span class="me-3">({{ usreList.length }}) Shopping Orders</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
          </v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="usreList" item-key="full_name" class="table-rounded"
          :items-per-page="10" disable-sort :search="search">
          <!-- name -->
          <template #[`item.full_name`]="{item}">
            <div class="d-flex flex-column" @click="getMoreInfo(item.id)" style="cursor: pointer">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
              <small>{{ item.location }}</small>
            </div>
          </template>
          <template #[`item.transaction_id`]="{item}">
            {{ item.transaction_id }}
          </template>
          <template #[`item.total_cost`]="{item}">
            {{ item.total_cost }}
          </template>
          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip small :color="statusColor[item.status]" class="font-weight-medium">
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.date_time`]="{item}">
            {{ item.date_time }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import data from './datatable-data'

export default {
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Pending: 'primary',
      Validated: 'success',
      Cancelled: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Name', value: 'full_name' },
        { text: 'Transaction ID', value: 'transaction_id' },
        { text: 'Total Cost', value: 'total_cost' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      usreList: data,
      statusColor,
      search: '',

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  methods:{
    getMoreInfo(info_id){
      this.$store.commit('setDialogTitle', "Getting More Information for "+info_id)
      this.$store.commit('setDialogMessage', "Your information has been collected. <b>After</b> choosing this, the next field you will have to choose the number of days/weeks/months. <ul><li>This is one</li><li>This is two</li><li>This is three</li><li>This is four</li></ul>")
      this.$store.commit('activateDialog', true)
    }
  },
}
</script>
